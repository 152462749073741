<template>
  <v-container class="login-container" ma0 fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md5 lg4 xl3>
        <v-card class="pb-4">
          <v-card-title>
            <v-layout align-center justify-center row fill-height mt-3>
              <Logo></Logo>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-flex sm10 offset-sm1>
              <v-form ref="form">
                <v-text-field
                  v-model="userName"
                  autofocus
                  prepend-inner-icon="fas fa-user fa-sm"
                  name="userName"
                  :label="$t('login.userName')"
                  type="text"
                  :rules="userNameRules"
                  @keydown.enter="onLoginClick"
                ></v-text-field>
                <v-text-field
                  id="password"
                  ref="passwordInput"
                  v-model="password"
                  prepend-inner-icon="fas fa-lock fa-sm"
                  name="password"
                  :label="$t('login.password')"
                  type="password"
                  :rules="passwordRules"
                  @keydown.enter="onLoginClick"
                ></v-text-field>
              </v-form>
              <div class="text-center pt-2">
                <v-btn
                  block
                  color="primary"
                  :loading="loading"
                  @click="onLoginClick"
                  >Zaloguj</v-btn
                >
              </div>
              <v-alert
                :value="loginError"
                color="error"
                icon="fas fa-exclamation-triangle"
                outline
                class="mt-3"
                transition="scale-transition"
                >{{ errorMessage }}</v-alert
              >
            </v-flex>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Logo from "../components/Logo";
export default {
  name: "Login",
  components: { Logo },
  data() {
    return {
      loading: false,
      loginError: false,
      userName: null,
      password: null,
      loginErrorMessage: null
    };
  },
  computed: {
    userNameRules() {
      return [
        v =>
          !!v ||
          this.$t("global.required", {
            value: this.$t("login.userName")
          })
      ];
    },
    passwordRules() {
      return [
        v =>
          !!v ||
          this.$t("global.required", {
            value: this.$t("login.password")
          })
      ];
    },
    errorMessage() {
      if (this.loginErrorMessage == null) {
        return "";
      }
      var msg = this.$t(`login.${this.loginErrorMessage}`);
      if (msg == `login.${this.loginErrorMessage}`) {
        return this.loginErrorMessage;
      }
      return msg;
    }
  },
  methods: {
    onLoginClick() {
      this.loginError = false;
      this.loginErrorMessage = null;
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("LOGIN", {
            userName: this.userName,
            password: this.password
          })
          .then(({ isError, loginStatus }) => {
            if (isError) {
              this.loginError = true;
              this.loginErrorMessage = loginStatus;
            } else {
              this.$router.push(
                this.$router.history.current.query.redirect || {
                  name: "rodo"
                }
              );
            }
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style lang="scss">
.login-container {
  background-color: var(--v-secondary-base);
}
.v-alert.v-alert--outline {
  border: 0px solid !important;
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .login-container {
    height: 100vh;
  }
}
</style>
